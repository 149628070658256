import React from 'react';

export const stepsScanner = () => {
    return (
        <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M62.8392 52.2308C62.9667 52.7726 62.2506 53.1105 62.0084 52.5368C61.7194 51.8355 61.2519 48.852 60.846 45.8515C60.6271 44.245 58.4936 44.4426 58.5 46.1023C58.5064 48.6841 58.5043 50.8368 58.5 54.6596C58.4957 57.9215 59.9811 57.9853 61.0776 61.6445C61.2646 62.2735 61.4261 62.9578 61.5579 63.6271L67 61.7253C65.1024 58.0129 66.0183 55.0825 66.2074 52.6834C66.4666 49.4045 65.5954 49.0858 61.5494 45.1439C61.7916 46.8779 62.2974 49.957 62.8392 52.2308V52.2308ZM21.4506 45.146C17.4046 49.0858 16.5334 49.4066 16.7926 52.6855C16.9817 55.0825 17.8976 58.0129 16 61.7253L21.4443 63.625C21.576 62.9578 21.7375 62.2714 21.9245 61.6424C23.0189 57.9853 24.5043 57.9194 24.5 54.6575C24.4957 50.8325 24.4936 48.682 24.5 46.1001C24.5064 44.4405 22.3707 44.2429 22.154 45.8494C21.7502 48.8499 21.2806 51.8334 20.9916 52.5346C20.7515 53.1105 20.0354 52.7705 20.1607 52.2286C20.7026 49.957 21.2084 46.8779 21.4506 45.146V45.146ZM26.625 55.125L26.6335 50.875H56.3665L56.375 55.125H26.625V55.125ZM22.375 42.7596V28.5625C22.375 26.803 23.803 25.375 25.5625 25.375H57.4375C59.197 25.375 60.625 26.803 60.625 28.5625V42.7596C60.3318 42.6746 60.0257 42.6215 59.6985 42.6215C58.8272 42.6194 57.973 42.9743 57.3567 43.5926C56.7426 44.2089 56.4026 45.0504 56.3729 45.9684V29.625H26.625V45.9641C26.5952 45.0461 26.2553 44.2068 25.6411 43.5905C25.0249 42.9721 24.1727 42.6194 23.2994 42.6194C22.9764 42.6194 22.6704 42.6746 22.375 42.7596V42.7596Z" fill="#4A4A4A"/>
            <path d="M51.739 39.921C50.392 39.531 49.854 39.383 48.187 39C48.187 39 45.808 36.641 45.355 36.184C44.787 35.612 44.312 35 42.406 35H34.512C34.001 35 33.776 35.547 34.442 36C33.7 36.602 32.823 37.38 32.184 38.027C30.749 39.482 30 40.412 30 42.282C30 44.042 31.042 46 33.174 46H33.184C33.597 47.162 34.696 48 36 48C37.304 48 38.403 47.162 38.816 46H45.183C45.596 47.162 46.695 48 47.999 48C49.303 48 50.402 47.162 50.815 46H51.5C53.494 46 54 44.224 54 42.835C54 40.794 52.877 40.251 51.739 39.921V39.921ZM36 46.2C35.338 46.2 34.8 45.662 34.8 45C34.8 44.338 35.338 43.8 36 43.8C36.662 43.8 37.2 44.338 37.2 45C37.2 45.662 36.662 46.2 36 46.2ZM39.576 40C38.505 40 36.076 39.894 34.357 39.25C34.935 38.5 35.355 38.028 35.627 37.714C35.945 37.346 36.5 37 37.188 37H39.576V40ZM40.576 37H42.411C43.293 37 43.839 37.493 44.433 38.105C44.885 38.571 46.165 40 46.165 40H40.577V37H40.576ZM48 46.2C47.338 46.2 46.8 45.662 46.8 45C46.8 44.338 47.338 43.8 48 43.8C48.662 43.8 49.2 44.338 49.2 45C49.2 45.662 48.662 46.2 48 46.2Z" fill="#4A4A4A"/>
            <circle cx="42" cy="42" r="38.5" stroke="#4A4A4A" strokeWidth="7"/>
        </svg>
    );
}