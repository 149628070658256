import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    font-family: 'Ubuntu', sans-serif;
    overflow-x: hidden;
    overflow-y: ${props => props.overflowY};
  }
`;